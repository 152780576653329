import React from 'react';
export const CustomLeftArrow = ({ onClick, ...rest }) => {
    // const {
    //   onMove,
    //   state: { currentSlide, deviceType } 
    // } = rest;
    // onMove means if dragging or swiping in progress.
    return <button className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right" onClick={() => onClick()} ><i className="fa fa-chevron-right" aria-hidden="true"></i></button>;
}
export const CustomRightArrow = ({ onClick, ...rest }) => {
    // const {
    //   onMove,
    //   state: { currentSlide, deviceType } 
    // } = rest;
    // onMove means if dragging or swiping in progress.
    return <button className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left" onClick={() => onClick()} ><i className="fa fa-chevron-left" aria-hidden="true"></i></button>;
};    
